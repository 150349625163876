import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { GenseeLiveBase, LiveChannel, LiveQAItem, PublicChat, QAItem, QAItemParsed } from './types.d'
import { chainLoadScript } from './utils'
import { action, observable, toJS } from 'mobx'
import { WidgetParams } from './playback'
import moment from 'moment'
import { uuid } from '@xt/client/utils/uuid'
import lodash from 'lodash'

declare const GS: GenseeLiveBase

@BindAll()
export class ControllerGenseeLive extends EntitiesBasics {
  private channel: LiveChannel

  // 展示互动直播组件上的参数
  @observable widgetParams: WidgetParams = {
    ownerid: 'b3A2hRCUui',
    uid: '880834096257830943',
    uname: 'test',
    k: ''
  }

  // 聊天
  @observable chatList: Array<PublicChat> = []

  @observable netSettingList: Array<{ netName: string; label: string; selected: boolean }> = []

  // qa
  private qaListOrigin: Array<LiveQAItem> = []
  @observable qaList: Array<QAItemParsed> = []

  // 音量
  @observable volume: number

  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})
  }

  async init(groupName: string) {
    const scriptLoaded = await chainLoadScript(['/assets/jquery-1.9.1.min.js', '/assets/gssdk-1.3.js'])

    if (scriptLoaded) {
      const channel = GS.createChannel(groupName)
      this.channel = channel
      this.bindEvent()
    }
  }

  private bindEvent() {
    this.channel.bind('onDataReady', () => {
      this.channel.send('requireNetSettings')
    })

    this.channel.bind('onPublicChat', ({ data }) => {
      console.log('onPubChat', data)
      this.appendChatList(data)
    })

    this.channel.bind('onPriChat', ({ data }) => {
      console.log('onPriChat', data)
    })

    this.channel.bind('onQAList', ({ data }) => {
      console.log('onQAList', data.list, this.parseQAList(data.list))
      this.setQAList(this.parseQAList(data.list))
    })

    this.channel.bind('onQA', ({ data }) => {
      // console.log('onQA', data)
      // this.qaListOrigin.push(data)
      // this.setQAList(this.parseQAList(this.qaListOrigin))
      // console.log('onQA~~~', toJS(this.qaList), this.qaListOrigin)
    })

    this.channel.bind('onMessage', ({ data }) => {
      console.log('onMessage', data)
    })

    this.channel.bind('onStart', () => {
      console.log('onStart')
    })

    this.channel.bind('onPause', () => {
      console.log('onPause')
    })

    this.channel.bind('onPlay', () => {
      console.log('onPlay')
    })

    this.channel.bind('onStop', () => {
      console.log('onStop')
    })

    this.channel.bind('onUserOnline', ({ data }) => {
      console.log('onUserOnline', data)
    })

    this.channel.bind('onNetSettings', ({ data }) => {
      console.log('onNetSettings', data)
      if (Array.isArray(data.list)) {
        this.setNetSettingList(data.list)
      }
    })

    this.channel.bind('onMute', ({ data }) => {
      console.log('onMute', data.mute)
    })
  }

  private getUUID() {
    return uuid()
  }

  public async submitChatMessage(msg: string) {
    // 处理msg
    msg = msg.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    if (msg.length > 250) {
      return Promise.resolve(false)
    }

    const that = this
    if (this.channel) {
      this.channel.send('submitChat', { content: msg, security: 'default' }, ({ data, result }) => {
        if (result === false) return Promise.resolve(false)

        that.appendChatList({
          id: data.id,
          sender: that.widgetParams.uname,
          senderId: that.getUUID(),
          content: data.content,
          richtext: data.richtext,
          utctime: Date.now(),
          senderUid: this.widgetParams.uid
        })

        return Promise.resolve(true)
      })
    }

    return Promise.resolve(false)
  }

  /**
   *
   * @param list
   * @returns
   */
  parseQAList(list: LiveQAItem[]): QAItemParsed[] {
    const formatData = Object.values(lodash.groupBy(list, 'id')).map(list =>
      list.sort((a, b) => Number(a.answerTime) - Number(b.answerTime))
    )

    const result: Array<QAItemParsed> = []
    formatData.forEach((list, index) => {
      list.forEach(item => {
        const { id, question, submitor, submitTime, qaownerId, answer, answerBy, answerTime } = item
        // 首次提问
        if (!item.answer) {
          result.push({
            id,
            question,
            submitor,
            submitTime: moment(parseInt(`${submitTime}`, 10) * 1000)
              .utcOffset(+8)
              .format('HH:mm:ss'),
            submitorId: qaownerId,
            answerList: []
          })
        } else {
          result[index].answerList.push({
            answer: answer,
            answerBy: answerBy,
            answerTime: moment(parseInt(`${answerTime}`, 10) * 1000)
              .utcOffset(+8)
              .format('HH:mm:ss'),
            // 没有answererId
            answererId: ''
          })
        }
      })
    })

    return result
  }

  /** 初始直播为静音自动播放，需要手动点击解除静音 */
  recoverVolumePlay() {
    this.channel.send('submitMute', { mute: false })
    // 初始默认声音设置成75
    this.setVolume(75)
    this.channel.send('submitVolume', { value: this.volume / 100 })
  }

  /**
   * 通过拖动音量进度条来改变视频播放音量
   * @param volume 0-100
   */
  updateVolumeByDrag(volume: number) {
    this.channel.send('submitVolume', { value: volume / 100 })
    this.setVolume(volume)
  }

  changeNetworkChoice(netName: string) {
    this.channel.send('submitNetChoice', { label: netName })
    this.channel.send('requireNetSettings')
  }

  @action
  setNetSettingList(list: Array<{ label: string; selected: 'true' | 'false' }>) {
    this.netSettingList = list.map(item => ({
      netName: item.label,
      label: item.label.indexOf('（') !== -1 ? item.label.split('（')[0] : item.label,
      selected: item.selected === 'true'
    }))
  }

  @action
  setQAList(list: QAItemParsed[]) {
    this.qaList = list
  }

  @action
  appendChatList(msg: PublicChat) {
    this.chatList.push(msg)
  }

  @action
  setWidgetParams(params: WidgetParams) {
    this.widgetParams = params
  }

  @action
  setVolume(value: number) {
    this.volume = value
  }
}
