




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class PlaybackPageView extends Vue {
  @Prop({ default: false }) toggle: boolean

  get PageStore() {
    return this.$store.$genseePlayback
  }

  created() {}
  mounted() {}
  destroyed() {}
}
