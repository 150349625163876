












import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class LivePageView extends Vue {
  word = ''

  get PageStore() {
    return this.$store.$genseeLive
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  created() {}
  mounted() {}
  destroyed() {}
}
