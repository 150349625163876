














import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { VideoState } from '@xt/client/entities/gensee/playback'
import Video from './components/video.vue'
import Doc from './components/doc.vue'
import Footer from './components/footer.vue'
import Chat from './components/chat.vue'
import Input from './components/input.vue'
import { parsePathParams } from '@xt/client/entities/gensee/utils'

@Observer
@Component({
  components: {
    Video,
    Doc,
    Footer,
    Chat,
    Input
  },
  head() {
    return {
      htmlAttrs: {
        'xmlns:gs': 'http://www.gensee.com/ec'
      }
    }
  },
  asyncData(ctx) {
    // 跳转展示互动携带的p参数
    const { p } = ctx.route.query

    if (!p) return ctx.app.router.replace({ path: '/' })

    const playbackStore = ctx.store.$genseeLive
    const { nickname, uid, k, sdkId, isGrayUser, genseeURL } = parsePathParams(p as string)
    if (!sdkId) ctx.app.router.replace({ path: '/' })
    // if (!isGrayUser) ctx.redirect(genseeURL)
    playbackStore.setWidgetParams({ uname: nickname, uid, ownerid: sdkId, k })
  },
  layout: 'clean'
})
export default class LivePageView extends Vue {
  // 是否让视频和文档大小屏幕切换  初始不切换
  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null

  get PageStore() {
    return this.$store.$genseeLive
  }

  // 切换屏幕
  onToggleScreen(toggle: boolean) {
    this.toggle = toggle
  }

  // handleStateChange(state: VideoState) {
  //   const { progress, isReady } = state
  //   console.warn('[handleStateChange]', JSON.stringify(state))

  //   if (isReady && !this.videoNode) {
  //     this.videoNode = document.querySelector('video')
  //     console.log(this.videoNode, '@@@@')
  //   }
  // }

  created() {}
  mounted() {
    this.PageStore.init('live-group')
    // this.PageStore.addStateChangeListener(this.handleStateChange)
  }
  destroyed() {}
}
