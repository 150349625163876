
























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class PlaybackPageView extends Vue {
  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null
  visibleChapter = false
  visibleSpeed = false
  visibleVolume = false
  dragVolumeSlider = false

  get PageStore() {
    return this.$store.$genseePlayback
  }

  formatTime(time: number) {
    const ms = Math.ceil(time)

    const hours = this.moment(ms).utcOffset(0).get('hours')

    return this.moment(ms)
      .utcOffset(0)
      .format(hours >= 1 ? 'HH:mm:ss' : 'mm:ss')
  }

  progrssChangeHandler(value: number) {
    this.PageStore.updateProgressByDrag(value)
  }

  progrssAfterChangeHandler(value: number) {
    this.PageStore.onProgressDragFinish(value)
  }

  volumeChangeHandler(value: number) {
    this.dragVolumeSlider = true
    this.PageStore.updateVolumeByDrag(value)
  }

  volumeAfterChangeHandler() {
    // 滑动滑块鼠标移出区域后  音量控件会被关掉
    setTimeout(() => {
      this.dragVolumeSlider = false
    }, 20)
  }

  toggleVideoPlayHandler() {
    this.PageStore.toggleVideoPlay()
  }

  @Emit('toggleScreen')
  toggleScreenHandler() {
    this.toggle = !this.toggle
    return this.toggle
  }

  fullScreenHandler() {
    ;(document.querySelector('#video-widget-box') as HTMLDivElement).requestFullscreen()
  }

  onVisible(visible: boolean, type: 'chapter' | 'speed' | 'volume') {
    this.resetClickHandler()
    if (visible) {
      switch (type) {
        case 'chapter':
          this.visibleChapter = true
          break
        case 'speed':
          this.visibleSpeed = true
          break
        case 'volume':
          this.visibleVolume = true
          break
      }
      this.recoverClickHandler()
    }
  }

  clickHandler(e: MouseEvent) {
    const node = e.target as HTMLElement
    const id = node.offsetParent?.id
    if ((id && id.startsWith('control-trigger')) || this.dragVolumeSlider) return
    this.resetClickHandler()
  }

  resetClickHandler() {
    this.visibleChapter = false
    this.visibleSpeed = false
    this.visibleVolume = false
    document.removeEventListener('click', this.clickHandler)
  }

  recoverClickHandler() {
    document.addEventListener('click', this.clickHandler)
  }

  created() {}
  mounted() {}
  destroyed() {}
}
