import CryptoJS from 'crypto-js'
import $global from '@xt/client/store/global'

export function loadScript(src: string): Promise<boolean> {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src

    script.onload = () => {
      resolve(true)
    }

    script.onerror = () => {
      resolve(false)
    }

    document.body.appendChild(script)
  })
}

export async function chainLoadScript(srcList: string[]) {
  for (const src of srcList) {
    const isSuccess = await loadScript(src)
    if (!isSuccess) return false
  }

  return true
}

export type ParseResult = {
  nickname: string
  uid: string
  k: string
  sdkId: string
  isGrayUser: boolean
  wxx: string
  genseeURL: string
}

/**
 * 解析展示互动链接参数
 * @param p url
 */
export function parsePathParams(p: string): Partial<ParseResult> {
  const url = new URL(p, $global.domain)
  const search = url.searchParams

  const nickname = search.get('nickname')
  const uid = search.get('uid')
  const k = search.get('k')
  const sdk = search.get('sdk')
  const isGray = search.get('isGray')
  const wxx = search.get('wxx')

  if (!sdk) return

  console.log({ url, nickname, uid, k, sdk, isGray, wxx })

  const genseeURL = new URL(url.origin + url.pathname)
  genseeURL.searchParams.set('nickname', nickname)
  genseeURL.searchParams.set('uid', uid)
  genseeURL.searchParams.set('k', k)
  if (wxx) {
    genseeURL.searchParams.set('wxx', wxx)
  }

  const word = CryptoJS.enc.Base64.parse(sdk)

  return {
    nickname,
    uid,
    k,
    sdkId: word.toString(CryptoJS.enc.Utf8),
    isGrayUser: isGray === 'true',
    wxx,
    genseeURL: genseeURL.toString()
  }
}
